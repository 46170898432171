import React, { useEffect } from 'react';
import ReactHtmlParser, { convertNodeToElement, Transform } from 'react-html-parser';
import { GetAllInternalInfoQuery } from '../../graphql-types';
import * as styles from '../styles/internalInfo.module.css';
import Body from '../components/article/body';
import Layout from '../components/layout';
import { useLocation } from '@reach/router';

interface Props {
  pageContext: {
    internalInfoData: GetAllInternalInfoQuery['allNodeInternalInfo']['nodes'][0];
  };
}
function transform(node: any, index: any) {
  const needExtraSpacing = ['li'].includes(node.name);
  const renderedNode = convertNodeToElement(node, index, transform);
  if (needExtraSpacing) {
    return (
      <div key={`${node.name}-${index}`}>
        {renderedNode}
        {needExtraSpacing ? <br /> : null}
      </div>
    );
  }
  return renderedNode;
}

const transformBody: Transform = function (node, index) {
  if (node.type !== 'tag') {
    return convertNodeToElement(node, index, transform);
  }

  if (node.name === 'img' && process.env.GATSBY_DRUPAL_URL) {
    if (node?.attribs?.src && node.attribs.src.startsWith('/sites/default')) {
      node.attribs.src = process.env.GATSBY_DRUPAL_URL.concat(node.attribs.src);
    }
  } else if (['p', 'ul'].includes(node.name)) {
    node.attribs.class = (node.attribs.class ?? '').concat(' body1');
  }

  if (['h1', 'h2', 'h3', 'h4', 'h5', 'img', 'p', 'ul'].includes(node.name)) {
    let extraStyle = '';
    switch (node.name) {
      case 'ul':
        extraStyle = 'list-decimal space-y-6 mobile:mx-5 tablet:mx-2 laptop-standard:mx-5';
        break;
      default:
        break;
    }

    const childrenRendered = convertNodeToElement(node, index, transform);

    return (
      <Body
        key={`${node.name}-${index}`}
        className={`laptop-standard:px-20 laptop-standard:block padding-lateral ${extraStyle} ${styles.internalInfo}`}
      >
        {extraStyle ? <div className={extraStyle}>{childrenRendered}</div> : childrenRendered}
      </Body>
    );
  }

  return convertNodeToElement(node, index, transform);
};

const InternalInfoBuilder: React.FC<Props> = ({ pageContext }) => {
  const location = useLocation();
  const { internalInfoData } = pageContext;

  useEffect(() => {
    if (location.hash.includes('#')) {
      const deepLinkReference = document.getElementById(location.hash.replace('#', ''));
      deepLinkReference?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.hash]);

  return (
    <Layout
      seo={{
        'title': internalInfoData?.title ?? '',
        'author': 'Deutsch LA',
        'keywords': 'read, deutsch',
      }}
    >
      <div className="pt-10 pb-15 laptop-standard:pt-25 laptop-standard:pb-40">
        <Body
          className={`laptop-standard:px-20 pt-6 pb-15 laptop-standard:block padding-lateral ${styles.internalInfo}`}
        >
          <h1>{internalInfoData.title?.toUpperCase()}</h1>
        </Body>

        {internalInfoData?.body?.processed &&
          ReactHtmlParser(internalInfoData.body.processed, {
            transform: transformBody,
          })}
      </div>
    </Layout>
  );
};

export default InternalInfoBuilder;
